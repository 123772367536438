import React from "react";
import { string } from "prop-types";
import { Wrapper } from "./Button.module.css";
import { classNames } from "../../helpers/classNames";

const Button = ({ className, ...props }) => {
  return (
    <button
      type="button"
      {...props}
      className={classNames([className, Wrapper])}
    />
  );
};

Button.propTypes = {
  className: string,
};

Button.defaultProps = {
  className: "",
};

export default Button;
