import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./LAII.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import LAIIPhoto1 from "../../../../res/Photos site/LA II/La-II.jpg"
import LAIIPhoto2 from "../../../../res/Photos site/LA II/Shafrazi_LA II-Keith Haring .jpg"
import LAIIPhoto3 from "../../../../res/Photos site/LA II/2.jpg"
import LAIIPhoto4 from "../../../../res/Photos site/LA II/LA II - Patti Astor by Eric Kroll.png"
import LAIIPhoto5 from "../../../../res/Photos site/LA II/LA II (2).jpg"
import LAIIPhoto6 from "../../../../res/Photos site/LA II/La II.jpg"
import LAIIPhoto7 from "../../../../res/Photos site/Photos Oeuvres Disponibles/LA II - Banana - 2003.png"
import CarrouselCartel from "../../../../components/CarrouselCartel";
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/laII/portrait.jpg'
const isBrowser = typeof window !== "undefined";
const infos ={
  path: "LAII",
  name: "LAII",
  description:
  "LA II (Angel Ortiz) was born in 1967 in the Lower East Side of New York, New York. Also known for the artistic names of LA II, LA2 or LA Rock which stands for “Little Angel” are often seen as tags in his artwork. LA II began his career when he met Keith Haring in 1980 at the age of 13. Being one of the most recognizable Graffiti artists from the Lower East Side in the 1980’s Ortiz collaborated side by side with Keith Haring for a number of projects. Having dropped out of High School in Manhattan, New York in 1983, he traveled around the world working with Keith Haring until 1985. LA II is for Haring what Jean-Michel Basquiat was for Andy Warhol. Today LA II is considered an iconic graffiti artist, being unearthed from the massive power of Keith Haring’s body of work LA II has participated at exhibitions from the most renowned galleries and museums such as : Tony Shafrazi Gallery, New York City, with Keith Haring in 1982, in 1983 alongside with Keith Haring at the Fun Gallery, New York City, at the Galerie Watari, Tokyo, Japan, at the Robert Fraser Gallery, London, United Kingdom, at the Special Projects Paint Fiorucci, Milan, Italy, in 1990 Future Primeval Queens Museum, Flushing Meadows and Corona Park, New York with Keith Haring, Follin Gallery, New York City in 2001, Clayton Patterson Outlaw Museum, New York City in 2002, LA II & Keith Haring, Due amici a New York in 2003, Scope Art Fair, Los Angeles, California in 2004, and Galleria Leonardo Galerie, Bolzano, Italy in 2005. LA II lives and works in New York.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de LAII.",
  photos: [
    { src: LAIIPhoto2, name: "LAII" },
    { src: LAIIPhoto5, name: "LAII" },
    { src: LAIIPhoto6, name: "LAII" },
  ],
  citations: []
};

const infos2 = {
  photos: [
    { src: LAIIPhoto7, name: "LA II", artist: "LA II\n(Angel ORTIZ dit, né en 1966)", title:"Banana, 2003", desc3:"167 x 147 cm", desc4:"65 3/4 x 57 7/8 in.", desc7: "- Collection particulière.", desc8:"<h4>-<i> Nos Fantômes - </i>exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021</h4>"},
  ],
};


const LAII = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>LAII <h2 style={{paddingLeft: "16px"}}>(Angel Ortiz - American)</h2></h1>
          <h3 className={NameWrapper} >1967 - 1998</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />

        </div>
      </div>
      

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default LAII;