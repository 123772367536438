// extracted by mini-css-extract-plugin
export var ArtistDescription = "LAII-module--ArtistDescription--PPTkO";
export var ArtistInfos = "LAII-module--ArtistInfos--bZaGY";
export var ButtonWrapper = "LAII-module--ButtonWrapper --iSlj-";
export var CardWrapper = "LAII-module--CardWrapper--miFeR";
export var CarrouselWrapper2 = "LAII-module--CarrouselWrapper2--Bd3Rk";
export var Citations = "LAII-module--Citations--nyVvk";
export var DescriptionWrapper = "LAII-module--DescriptionWrapper--S3A-w";
export var ImageWrapper = "LAII-module--ImageWrapper--gzEHt";
export var LinkWrapper = "LAII-module--LinkWrapper--5ZST7";
export var MobileProtrait = "LAII-module--MobileProtrait--Z8JiE";
export var More = "LAII-module--More--ZN6dT";
export var MoreButton = "LAII-module--MoreButton--TnLhk";
export var NameWrapper = "LAII-module--NameWrapper--zGB1d";
export var PdpWrapper = "LAII-module--PdpWrapper--zRzIf";
export var PhotosWrapper = "LAII-module--PhotosWrapper--lsdau";
export var ProfilWrapper = "LAII-module--ProfilWrapper--ub4H4";
export var TitleWrapper = "LAII-module--TitleWrapper--FMTtR";
export var Wrapper = "LAII-module--Wrapper--AlzcX";